@import '../../../or_base/scss/theme-bootstrap';

.tabbed-products-block {
  margin: 0 auto;
  width: 100%;
  @include breakpoint($medium-up) {
    width: 90%;
  }
  @include breakpoint($large-up) {
    width: 80%;
    max-width: 1070px;
  }
  &__tabs {
    margin: 25px auto;
    text-align: center;
    a {
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .product-grid-placeholder & {
      margin: 10px auto 0;
    }
  }
  &__header {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }
  }
  &__subtitle {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }
  }
  &__content-tabs {
    display: inline-block;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }
  }
  &__tab {
    color: $color-black;
    cursor: pointer;
    display: inline-block;
    margin: 16px 16px 0;
    text-transform: uppercase;
    &:hover {
      text-decoration: none;
    }
    &.current {
      border-bottom: 2px solid $color-black;
      font-family: $font--futura-medium;
    }
  }
  &__content {
    margin: 0 auto 25px;
    padding: 0 40px;
    @include breakpoint($medium-up) {
      margin: 0 25px 25px;
    }
    @include breakpoint($large-up) {
      padding: 0;
      margin: 0 auto 25px;
    }
    .grid--mpp {
      padding: 0;
      position: relative;
      &.grid--mpp__carousel {
        .grid--mpp__items {
          margin: 0 auto;
          width: 100%;
          box-sizing: border-box;
          .grid--mpp__item {
            margin: 0 auto;
            @include breakpoint($medium-up) {
              padding: 0 5px;
            }
            @include breakpoint($large-up) {
              padding: 0 0.5em;
              &:nth-child(3) {
                border-right: none;
              }
            }
          }
        }
      }
    }
  }
  &__content-item {
    height: 0;
    width: 100%;
    overflow: hidden;
    transition: opacity 1s ease-in-out;
    opacity: 0;
    &.current {
      height: auto;
      overflow: visible;
      opacity: 1;
    }
  }
  .carousel-controls {
    height: 0;
    display: block;
    left: 0;
    position: absolute;
    top: 25%;
    width: 100%;
    @include breakpoint($medium-up) {
      top: 29%;
    }
  }
  .slick-prev,
  .slick-next,
  .slick-prev:before,
  .slick-next:before {
    font-size: 20px;
    line-height: 1;
    color: $color-black;
  }
  .slick-prev {
    @include bordered-triangle(33px, $color-white, left, $color-black);
    left: -38px;
    position: absolute;
    @include breakpoint($medium-up) {
      left: -80px;
    }
    &:before {
      content: ' ';
    }
    &:after {
      border-width: 19.5px;
      top: -3px;
      left: -3px;
    }
  }
  .slick-next {
    @include bordered-triangle(33px, $color-white, right, $color-black);
    right: -38px;
    position: absolute;
    @include breakpoint($medium-up) {
      right: -80px;
    }
    &:before {
      content: ' ';
    }
    &:after {
      border-width: 19.5px;
      top: -3px;
      left: -3px;
    }
  }
}
